/*******************************
         Site Overrides
*******************************/
:not(.vertical).ui.menu .item {
    color: @black;
    margin-top: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

:not(.vertical).ui.menu .header.item {
    color: @black;
    margin-top: 8px;
    padding-top: 0px;
    padding-bottom: 8px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

:not(.vertical).ui.menu .item.active {
    background-color: @veryLightYellow;
}
