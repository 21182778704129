/*******************************
        User Overrides
*******************************/
.ui.vertical.tabular.menu .active.item {
    overflow: hidden;
    padding-right: 0;
    text-overflow: ellipsis;
    background-color: @veryLightYellow;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.ui.vertical.tabular.menu .item {
    overflow: hidden;
    padding-right: 0;
    text-overflow: ellipsis;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}